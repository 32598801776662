import API from "serviceshift-ui/api-client";
import ServiceShiftUI from "serviceshift-ui";
import Vue, { computed } from "vue";
import VueTheMask from "vue-the-mask";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";

import * as mixins from "@/mixins";
import usePaymentMethods from "@/modules/paymentMethods/usePaymentMethods";
import "./registerServiceWorker";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

// Set up our paymentMethods hook to always have a reference to the current customer
const { setCustomerRef } = usePaymentMethods();
setCustomerRef(computed(() => store.state.user as any));

Vue.config.productionTip = false;

// Init api based on env variables
API.init({
  API_BASE_URL: import.meta.env.VITE_API_BASE_URL
});

// API.init({
//   API_BASE_URL: "https://api.stage.serviceshift.com"
// });

// Loads key for autocomplete and map
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: import.meta.env.VITE_GOOGLE_KEY
});

Vue.use(ServiceShiftUI);

// Input masking
Vue.use(VueTheMask);

Vue.mixin({
  methods: { ...mixins }
});

new Vue({
  router,
  store,
  vuetify,
  render: (h: any) => h(App)
} as any).$mount("#app");
