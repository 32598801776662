<template>
  <div class="header-content">
    <div class="d-flex full-width justify-content-between align-items-center">
      <img :src="quoteLogo" alt="logo" class="logo-img" />
      <div class="table-container">
        <Table>
          <thead>
            <tr>
              <th colspan="2" class="table-header-text">
                <b>Quote</b>
                #{{ quote.id }}
                <span v-if="quote.job_id">
                  for
                  <b>Job</b>
                  #{{ quote.job_id }}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="table-left-cell">Bill to</td>
              <td class="table-info-cell">
                <div class="customer-text">
                  {{ customerName }}
                </div>
                <div>{{ billingAddress }}</div>
              </td>
            </tr>
            <tr>
              <td class="table-left-cell">Service Address</td>
              <td class="table-info-cell">
                <div class="location-name-text">
                  {{ serviceAddressLocationName }}
                </div>
                {{ serviceAddress }}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
    <div class="quote-details">
      <h3 v-if="quote.name" class="quote-name">
        {{ quote.name || "New Quote" }}
      </h3>
      <p v-if="quote.summary" class="quote-summary">
        {{ quote.summary }}
      </p>
    </div>
    <div class="summary-text">QUOTE SUMMARY</div>
  </div>
</template>

<script>
import Table from "serviceshift-ui/components/General/Table.vue";
import * as QuoteInvoiceTypes from "serviceshift-ui/components/QuoteInvoice/interface";

export default {
  components: {
    Table
  },
  props: {
    /** @type { QuoteInvoiceTypes.Quote } */
    quote: {
      type: Object,
      default: null
    },
    /** @type { QuoteInvoiceTypes.Customizations } */
    customizations: {
      type: Object,
      default: null
    }
  },
  computed: {
    quoteLogo() {
      if (this.customizations) {
        const darkLogo = this.customizations.Assets.dark_logo_url;
        return darkLogo.value || darkLogo.default;
      }
      return "";
    },
    customerName() {
      return this.quote && this.quote.customer
        ? this.quote.customer.full_name
        : "";
    },
    billingAddress() {
      return this.quote && this.quote.billing_address
        ? this.formatAddressLines(this.quote.billing_address)
        : "";
    },
    serviceAddress() {
      return this.quote && this.quote.service_address
        ? this.formatAddressLines(this.quote.service_address)
        : "";
    },
    serviceAddressLocationName() {
      return this.quote && this.quote.service_address
        ? this.quote.service_address.name
        : "";
    }
  },
  methods: {
    formatAddressLines(address) {
      const { street, city, state } = address;
      return `${street}\n${city}, ${state} ${address.zip_code}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.quote-details {
  margin: 15px 0;
}

tbody {
  border: 1px solid $medium-color !important;
}

.header-content {
  display: flex;
  flex-direction: column;
}

.logo-img {
  max-width: 400px;
  max-height: 100px;
  flex-shrink: 0;
}

.table-left-cell {
  padding: 10px !important;
  background-color: $angel-blue;
  width: 75px;
  height: 60px;
  font-size: 14px;
  font-weight: bolder;
  border: 1px solid $medium-color !important;
  text-align: left;
}

.table-info-cell {
  padding: 10px !important;
  background-color: white;
  font-size: 12px;
  white-space: pre-line;
  text-align: left;
  min-width: 156px;
}

.summary-text {
  font-size: 14px;
  font-weight: bolder;
  line-height: 16px;
  margin-bottom: 15px;
}

.table-container {
  display: flex;
  align-items: flex-end;
  min-width: 400px;
  max-width: 50%;
}

.quote-name {
  margin: 0;
}
.quote-address {
  margin: 8px 0 0 0;
}
.quote-summary {
  margin-top: 8px;
  white-space: pre-line;
}

.customer-text,
.location-name-text {
  font-weight: bold;
}

.table-header-text {
  text-align: left;
  font-weight: normal;
  white-space: nowrap;
  padding: 5px 10px !important;
  border-right: 1px solid #7e8fb4;
}
</style>
